(<template>
  <shared-pricing class="int-phone-details">
    <div class="int-phone-details__data-cont">
      <div class="int-phone-details__data-row">
        <p class="int-phone-details__data-text">{{ willCallText }}</p>
        <a :href="`tel:${interPhone}`"
           class="sk-link int-phone-details__data-phone js-copy-to-clipboard">{{ interPhone }}</a>
      </div>
    </div>
    <slot></slot>
  </shared-pricing>
</template>)

<script>
  import {mapState} from 'vuex';
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';

  export default {
    components: {
      'shared-pricing': SharedPricing
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        contractInfo: ({jobCallDetails}) => {
          return jobCallDetails && jobCallDetails.contractInfo ? jobCallDetails.contractInfo[0] : {};
        }
      }),
      interPhone() { return this.contractInfo.phone || ''; },
      jobOwner() { return this.contractInfo.businessName || ''; },
      willCallText() {
        const template = this.$gettext('%{ name } will call you on:');
        return this.$gettextInterpolate(template, {name: this.jobOwner});
      }
    }
  };
</script>

<style scoped>
  .int-phone-details__data-cont {
    display: block;
    width: 100%;
    margin-top: 20px;
  }

  .int-phone-details__data-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  .int-phone-details__data-text {
    padding-right: 3px;
    color: #333;
    font-weight: bold;
    font-size: 12px;
  }

  .int-phone-details__data-phone {
    color: #ff5b24;
    color: var(--color-secondary);
    font-weight: bold;
  }

  @media (max-width: 767px) {
    .int-phone-details__data-row {
      flex-wrap: wrap;
      padding: 10px 15px;
    }
  }
</style>
